require('./lib/ie10-viewport-bug.js');
require('./lib/addtohomescreen.js');

addToHomescreen({
    // debug: 'ios',           // activate debug mode in ios emulation
    skipFirstVisit: false, // show at first access
    // startDelay: 0,          // display the message right away
    // lifespan: 0,            // do not automatically kill the call out
    displayPace: 0, // do not obey the display pace
    // privateModeOverride: true,   // show the message in private mode
    maxDisplayCount: 1,
});

$(function() {
    $('[data-toggle="popover"]').popover()

    $('.tip').tooltip({
        container: 'body'
    });

    // rewards gallery functionality
    var $btnFilter = $('.btn-filter');
    var $btnSort   = $('.btn-sort');

    var $filterTarget = $($btnFilter.data('target'));
    var $sortTarget   = $($btnSort.data('target'));

    $filterTarget.on('show.bs.collapse', function() {
        $sortTarget.collapse('hide');
    });

    $sortTarget.on('show.bs.collapse', function() {
        $filterTarget.collapse('hide');
    });
});

// like functionality
$(function() {

    $('.heart').on('click', function(e) {
        var $this = $(this);
        var likeId = $this.data('like-id');
        $this.toggleClass('liked');

        $this.tooltip({
            container: 'body',
        });

        if ($this.hasClass('liked')) {
            like(likeId);
            $this.tooltip('enable');
            $this.tooltip('show');
        } else {
            unlike(likeId);
            $this.tooltip('hide');
            $this.tooltip('disable');
        }
    });


    function like(likeId){
        console.log('like', likeId);
    }

    function unlike(likeId){
        console.log('unlike', likeId);
    }

});

